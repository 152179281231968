body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.radio {
    text-align: center;
    background-image: url(/static/media/watcher.07bba05c.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: screen;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #ffe000;
}

.yellow {
    color: #ffe000;
}

.rg-title {
    position: fixed;
    left: 5px;
    bottom: 9%;
    transform: rotate(-90deg);
    transform-origin: top left;
    font-size: 16pt;
    color: #ffe000;
}
.rg-title .lipstick{
    font-size: 17pt;
    color: #880000;
}

.set-logo-image-dimensions {
    height: 100px;
}

.right-floater {
    float: right;
}

/* For the shortcuts component */
.callout {
    position: fixed;
    top: 50px;
    right: 50px;
    max-width: 250px;
}

.callout-header {
    /* top right bottom left */
    padding: 7px;
    background: #ffe000;
    color: #0e0000;
    cursor: pointer;
    border-radius: 0.2em;
    margin-bottom: 27px;
    width: 200px;
}

.callout-header span {
    font-size: 10pt;
}

.callout-header-image-dimensions {
    height: 15px;
    width: 15px;
}

.callout-container {
    /* top right bottom left */
    padding: 5px;
    color: #fffff0;
    font-size: 10pt;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif
}

.speech-bubble {
    position: relative;
    background: #880000;
    border-radius: .4em;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #880000;
    border-top: 0;
    margin-left: -20px;
    margin-top: -20px;
}

.callout-container p {
    text-align: left;
    padding-left: 10px;
    font-size: 10pt;
    line-height: 10px;
}

.callout-container span {
    color: #ffe000;
}

.play-pause-img-dimensions {
    height: 50px;
    width: 50px;
}

.add-some-margin {
    margin-top: 10px;
    margin-bottom: 20px;
}

.cursor-to-hand {
    cursor: pointer;
}

.mute-btn-dimensions {
    height: 25px;
    width: 30px;
}

/* for the slider */
input[type="range"] {
    -webkit-appearance: none;
    width: 12rem;
    height: 0.3rem;
    background: #ffe000;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateY(-10px);
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #880000;
    position: relative;
    border: 3px solid #ffe000;
    z-index: 3;
    cursor: pointer;
}

.add-left-margin {
    /* margin-left: 2px; */
    font-size: 15pt;
    color: #ffe000;
}

.spectrum-img-dimensions {
    height: 7rem;
    width: 50%;
 mix-blend-mode: overlay;
  filter: contrast(0.7) brightness(70%);}

.radio-link {
    margin-top: 4rem;
    font-size: 8pt;
    line-height: 1.2;
}

.radio-link,
a {
    color: #880000;
    text-decoration: none;
}


.radio-link-img-dimensions {
    height: 10px;
    width: 10px;
}

.thisimg {
    padding-top: 4px;
    margin-right: 2px;
}

/* For mobile */
@media (min-width: 320px) and (max-width: 480px) {

    .rg-title {
        top: 60%;
        font-size: 12pt;
        left: -5px;
    }

    .set-image-dimensions {
        height: 100px;
        width: 160px;
    }

    /* For the shortcuts component */
    .callout {
        position: static;
    }

    .callout-header {
        /* top right bottom left */
        padding: 5px 5px 5px 5px;
    }

    .callout-header-image-dimensions {
        height: 15px;
        width: 15px;
    }

    .callout-container {
        /* top right bottom left */
        padding: 5px 5px 5px 5px;
    }

    .spectrum-img-dimensions {
        height: 45px;
        width: 90%;
    }

    .radio-link {
        margin-top: 20px;
    }

    input[type="range"] {
        width: 200px;
    }
}

